import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import SEO from "../components/seo"
import PhotoGallery from "../components/photo-gallery"

const galleryMeta = {
  "location/Patio Dining Night (Katy Rayne Photography).jpg": {
    caption: "Patio dining by firelight.",
    credit: "Katy Rayne Photography",
    alt:
      "An outdoor dining patio at night illuminated by fire from tall propane heaters",
  },
  "location/Patio Dining Day (Katy Rayne Photography).jpg": {
    caption: "Our patio dining area",
    credit: "Katy Rayne Photography",
    alt: "Dining tables strewn across a long storefront terrace",
  },
  "location/Bar At Night 1 (Katy Rayne Photography).jpg": {
    caption: "The Bar",
    credit: "Katy Rayne Photography",
    alt:
      "A clean and well-stocked bar with empty chairs and alcohol stacked to the roof on glass shelves",
  },
  "location/Bar At Night 2 (Katy Rayne Photography).jpg": {
    caption: "The Bar",
    credit: "Katy Rayne Photography",
    alt:
      "Close-up of an empty bar with five cocktail shakers neatly lined up and ready for service",
  },
  "location/Dealers Choice At The Bar (Katy Rayne Photography).jpg": {
    caption:
      "Cocktails in production. The Dealer’s Choice (let the bartender make a drink according to your whims).",
    credit: "Katy Rayne Photography",
    alt:
      "Three cocktails atop the bar grate. A tall highball glass on the left, followed by a shorter coupe glass, with an even shorter rocks glass on the right.",
  },
  "location/Mechanical Cocktail Shakers (Katy Rayne Photography).jpg": {
    caption: "Our mechanical cocktail shakers.",
    credit: "Katy Rayne Photography",
    alt:
      "Close-up of two ice cold cocktail shakers, dripping with condensation, attached to a mechanical structure.",
  },
}

const ContactPage = () => {
  const galleryQuery = useStaticQuery(graphql`
    query {
      gallery: allFile(filter: { relativeDirectory: { eq: "location" } }) {
        edges {
          node {
            id
            relativePath
            photos: childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 2400
                quality: 70
                avifOptions: { quality: 55 }
              )
            }
            thumbnails: childImageSharp {
              id
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 1640
                quality: 70
                avifOptions: { quality: 55 }
                aspectRatio: 1
                transformOptions: { cropFocus: ENTROPY }
              )
              resize(height: 154) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const galleryData = galleryQuery.gallery

  return (
    <Layout>
      <SEO title="Visit Us" />
      <Wrapper sizeReadable>
        <h2>Visit Us</h2>
        <p>
          We are located in Theatre Square in the heart of Downtown Orinda and
          just a short walk from Orinda BART Station. Our menu changes with the
          seasons, and we have a full bar serving craft cocktails, beers on
          draft, and wine. Join us for indoor dinning or on our spacious patio.
        </p>
      </Wrapper>
      <Wrapper sizeReadable>
        <iframe
          title="Brothers Public Kitchen on Google Maps"
          height="450"
          style={{ border: 0, width: "100%", height: "480px" }}
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJVVVVMCl9hYARcwKGg1q3Tpc&key=AIzaSyCXYItLqDMeGPqVQ2HJIXW4AJg5RhAUMfY"
        ></iframe>
      </Wrapper>
      <Wrapper sizeReadable>
        <PhotoGallery
          galleryData={galleryData.edges}
          galleryMeta={galleryMeta}
          group="location"
          thumbSrcSizes="(min-width: 818px) 380px, (min-width: 992px) 444px, 45vw"
        />
      </Wrapper>
    </Layout>
  )
}

export default ContactPage
